<template>
  <div class="list">
    <div class="con default2" v-if="type === 'lottery'">
      <van-field
        class="t-search"
        readonly
        v-model="date"
        @click="showPicker = true"
        :label="$t('public.sdate')"
        label-width="100"
        :placeholder="$t('public.sdate2')"
        input-align="right"
      />
    </div>
    <div v-if="type === 'lottery'" class="van-tab__panel">
      <van-row class="t-head con">
        <van-col span="6">{{ $t("table.text1") }}</van-col>
        <van-col span="6">{{ $t("table.text2") }}</van-col>
        <van-col span="6">{{ $t("table.text4") }}</van-col>
        <van-col span="6">{{ $t("table.text5") }}</van-col>
      </van-row>
      <van-row
        class="t-body con"
        v-for="(item, idx) in dataList.lotteryList"
        :key="idx"
      >
        <van-col span="6">{{ item.issue }}</van-col>
        <van-col span="6">{{ item.method_name }}</van-col>
        <van-col span="6">{{ item.total_price }}</van-col>
        <van-col span="6" :class="item.bonus > 0 ? 'green' : ''">{{
          item.bonus
        }}</van-col>
      </van-row>
    </div>
    <!-- 充值记录 -->
    <div v-else-if="type === 'pay'" class="van-tab__panel">
      <van-row class="t-head con">
        <van-col span="6">{{ $t("table.text6") }}</van-col>
        <van-col span="6">{{ $t("table.text7") }}</van-col>
        <van-col span="4">{{ $t("table.text8") }}</van-col>
        <van-col span="8">{{ $t("table.text9") }}</van-col>
      </van-row>
      <van-row
        class="t-body con"
        v-for="(item, idx) in dataList.depositList"
        :key="idx"
      >
        <van-col span="6">{{ item.payment_channel }}</van-col>
        <van-col
          :class="
            item.status === 2 ? 'green' : item.status === 3 ? 'red' : 'blue'
          "
          span="6"
          >{{ parseInt(item.amount) }}</van-col
        >
        <van-col
          span="4"
          :class="
            item.status === 2 ? 'green' : item.status === 3 ? 'red' : 'blue'
          "
          >{{ item.status_label }}
        </van-col>
        <van-col span="8">{{ item.created_at }}</van-col>
        <van-row
          v-if="item.admin_remark !== ''"
          class="red"
          style="
            height: 30px;
            margin: 14px 6px 6px 6px;
            color: #ff5555;
            line-height: 30px;
          "
          >备注：{{ item.admin_remark }}</van-row
        >
      </van-row>
    </div>
    <!-- 提款记录 -->
    <div v-else-if="type === 'take'" class="van-tab__panel">
      <van-row class="t-head con">
        <van-col span="6">{{ $t("table.text10") }}</van-col>
        <van-col span="6">{{ $t("table.text11") }}</van-col>
        <van-col span="4">{{ $t("table.text12") }}</van-col>
        <van-col span="8">{{ $t("table.text13") }}</van-col>
      </van-row>
      <van-row
        class="t-body con"
        v-for="(item, idx) in dataList.withdrawalList"
        :key="idx"
      >
        <van-col span="6">{{ item.bank_name }}</van-col>
        <van-col span="6" :class="item.front_status === 6 ? 'green' : 'blue'">{{
          parseInt(item.amount)
        }}</van-col>
        <van-col span="4" :class="item.front_status === 6 ? 'green' : 'blue'">{{
          item.front_status_label
        }}</van-col>
        <van-col span="8">{{ item.created_at }}</van-col>
        <van-row
          v-if="item.remark !== ''"
          class="red"
          style="
            height: 30px;
            margin: 14px 6px 6px 6px;
            color: #ff5555;
            line-height: 30px;
          "
          >备注：{{ item.remark }}</van-row
        >
      </van-row>
    </div>
    <!-- 历史开奖记录 -->
    <div v-else class="van-tab__panel lottery-list">
      <van-row class="t-head con">
        <van-col span="8">{{ $t("table.text14") }}</van-col>
        <van-col span="10">{{ $t("table.text15") }}</van-col>
        <van-col span="6">{{ $t("table.text16") }}</van-col>
      </van-row>
      <van-row
        class="t-body con"
        v-for="(item, idx) in dataList.lotteryHistory"
        :key="idx"
      >
        <van-col span="8">{{ item.issue }}</van-col>
        <van-col span="10">
          <div class="lottery-box">
            <span v-for="item in item.code" :key="item">{{ item }}</span>
          </div>
          <div class="lottery-box">
            <div class="sum">
              {{ item.code.reduce((prev, cur) => Number(prev) + Number(cur)) }}
            </div>
          </div>
        </van-col>
        <van-col span="6" class="lottery-box">{{ item.belong_date }}</van-col>
      </van-row>
    </div>
    <van-calendar
      v-model:show="showPicker"
      type="range"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="onConfirm"
    />
    <van-loading v-if="loading" />
  </div>
</template>

<script setup>
import dayjs from "dayjs";
import { ref, reactive, toRaw } from "vue";
import { getDepositList, getWithdrawalList, getLottery } from "@/api/money";
import { getLotteryHtry } from "@/api/lottery";
import { useRouter } from "vue-router";
const router = useRouter();
const loading = ref(true);
const date = ref("");
const { type } = router.currentRoute.value.params;
const { ident } = router.currentRoute.value.query;
const showPicker = ref(false);
const minDate = ref(new Date(2020, 0, 1));
const maxDate = ref(new Date(2025, 10, 1));
const dataList = reactive({
  depositList: [],
  withdrawalList: [],
  lotteryList: [],
  lotteryHistory: [],
});
const statusList = ref([]);
const form = reactive({
  created_at: [],
  endDate: "",
  password: "",
  cardId: "",
  ident: ident,
  page: 1,
});
const formatDate = (date) => dayjs(date).format("YYYY-MM-DD");
const formatDateNew = (date) => dayjs(date).format("YYYY-MM-DD HH:mm:ss");
const onConfirm = (values) => {
  const [start, end] = values;
  form.created_at.push(`${formatDateNew(start)}`, `${formatDateNew(end)}`);
  date.value = `${formatDate(start)} - ${formatDate(end)}`;
  getResData(type);
  showPicker.value = false;
};

const getResData = (type) => {
  let fn;
  let key = "";
  switch (type) {
    case "pay": //充值记录
      fn = getDepositList;
      key = "depositList";
      break;
    case "take": //提款记录
      fn = getWithdrawalList;
      key = "withdrawalList";
      break;
    case "lottery": //游戏记录
      fn = getLottery;
      key = "lotteryList";
      break;
    case "lotteryHistory": // 历史开奖
      fn = getLotteryHtry;
      key = "lotteryHistory";
      break;
  }
  if (!fn) {
    return;
  }
  loading.value = true;
  fn(toRaw(form))
    .then((res) => {
      dataList[key] = res.data.list || res.data.record || res.data.history_code;
      statusList.value = res.data.front_status_labels;
      console.log(res, key, dataList);
    })
    .finally(() => {
      loading.value = false;
    });
};
getResData(type);
</script>

<style lang="less" scoped>
.list {
  color: #ddd;

  .mid {
    font-size: 20px;
    line-height: 25px;
  }

  :deep(.van-field__control) {
    text-align: center !important;
  }

  .lottery-list {
    .van-row.t-body {
      &:nth-child(odd) {
        background: #302e55;
      }

      .van-col {
        font-size: 14px;
        line-height: 27px;
      }
    }
  }

  :deep(.lottery-box) {
    color: #fff;
    text-align: center;

    span,
    .sum {
      width: 23px;
      height: 23px;
      line-height: 22px;
      font-size: 13px;
      margin: 0 5px;
    }

    span {
      background: linear-gradient(36deg, #673ab7, #00bcd4);

      &::after {
        top: 0px;
        left: 19px;
      }
    }
  }

  .t-search,
  .t-head {
    background: #302e55;

    .van-field__control {
      height: 45px !important;
    }
  }

  .t-head,
  .t-body {
    text-align: center;
    border-bottom: 1px solid #302e55;
  }

  .t-body {
    font-size: 12px;
  }

  :deep(.van-badge__wrapper) {
    display: none !important;
  }

  .van-cell.van-field {
    padding: 0 !important;
    height: 35px !important;
    line-height: 30px !important;
  }
}
</style>
